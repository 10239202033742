.emoji-icon {
    width: 95px;
    height: 90px;
    margin: 15px 15px 15px;
    display: inline-block;    
    position: relative;
    background-size: 80%;
    background-repeat: no-repeat;
    background-position: center;
}
.emoji-icon.emoji-icon--haha{
    background-image: url('icons/good.svg');
}
.emoji-icon.emoji-icon--haha-love{
    background-image: url('icons/love-it.svg');
}
.emoji-icon.emoji-icon--yay{
    background-image: url('icons/its-ok.svg');
}
.emoji-icon.emoji-icon--sad{
    background-image: url('icons/not-good.svg');
}
.emoji-icon.emoji-icon--haha.active{    
    position: relative;
}
.emoji-icon.emoji-icon--haha-love.active{    
    position: relative;
}
.emoji-icon.emoji-icon--yay.active{    
    position: relative;
}
.emoji-icon.emoji-icon--sad.active{    
    position: relative;
}
.emoji-pick-sec li p
{
  font-weight: 700 !important;
}
.emoji-icon.active:before {
    position: absolute;
    width: 100px;
    height: 100px;
    border: 5px solid #fbac2d;
    left: -5px;
    top: -5px;
    content: '';
    border-radius: 10px;
}